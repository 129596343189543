<template>
  <div class="text-center">...</div>
</template>

<script>
export default {
  methods: {
    async CHECK_QUERY() {
      const q = this.$route.query;
      if (q.email && q.password) {
        const response = { message: "OK" };
        await this.$api.post("Users/CreateExternalUser", {
          email: q.email,
          password: q.password,
          fullname: q.name || "Heocademy Yönetim Paneli",
        });
        if (response.message === "OK") {
          this.$router.push({ name: "SignIn", query: q });
        }
      } else {
        this.$router.push({ name: "SignIn" });
      }
    },
  },
  mounted() {
    this.CHECK_QUERY();
  },
};
</script>

<style></style>
